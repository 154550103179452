import React, { Component } from 'react';
import { connect } from 'react-redux';
import './SupplierManage.scss';
import { emitter } from "../../utils/emitter";

import * as supplierService from "../../services/supplierService";
import * as messageService from "../../services/messageService"
import { ReactNotifications } from 'react-notifications-component';
import SupplierModal from './SupplierModal';
import DataTable from 'react-data-table-component';


class SupplierManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            isOpenModal: false,
            titleModal: '',
            searchText: '',
            filterModel: {
                searchText: '',
                pageNum: 1,
                pageSize: 10,
            },
            totalCount: 0,
        }

    }

    componentDidMount() {
        this.getSuppliers();
    }
    onChangeInput = (event) => {
        this.setState({
            searchText: event.target.value
        }, () => {
            this.getSuppliers();
        })
    }
    getSuppliers = async () => {
        let res = await supplierService.getListSupplier(this.state.filterModel);
        if (res == 0) {
            messageService.messageNotify('', 'Đã có lỗi xảy ra, vui lòng thử lại!', 'warning');
            return;
        }
        else if (res.isSuccess) {
            let copyState = { ...this.state.listData };
            copyState = res.data;
            this.setState({
                listData: copyState,
                totalCount: res.data[0] ? res.data[0].totalCount : 0
            })
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
            return;
        }
    }
    onPageChange = (pageSize, pageNum) => {
        this.state.filterModel.pageNum = pageNum;
        if (pageSize != 0) this.state.filterModel.pageSize = pageSize;
        this.getSuppliers();
    }
    toggleSupplierModal = () => {
        this.setState({
            isOpenModal: !this.state.isOpenModal
        })
    }
    openModal(data) {
        emitter.emit("DATA_MODAL", data);
        this.setState({
            isOpenModal: true,
            titleModal: 'Sửa supplier'
        })
    }
    openModalPoint(data) {
        emitter.emit("CLEAR_DATA_MODAL_POINT", data);
        this.setState({
            isOpenModalPoint: true
        })
    }
    save = async (data) => {
        let res;
        res = await supplierService.update(data);
        if (res == 0) {
            messageService.messageNotify('', 'Đã có lỗi xảy ra, vui lòng thử lại!', 'warning');
            return;
        }
        else if (res.isSuccess) {
            this.getSuppliers();
            this.setState({
                isOpenModal: false
            })
            messageService.messageNotify('', 'Cập nhật dữ liệu thành công', 'success');
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
            return;
        }
    }

    render() {
        let listData = this.state.listData
        const paginationComponentOptions = {
            rowsPerPageText:"Số dòng",
            rangeSeparatorText:"của" 
        };
        const columns = [
            {
                name: 'STT.',
                selector: (row, index) => index + 1,
            },
            {
                name: 'Mã',
                selector: row => row.code,
            },
            {
                name: 'Tên',
                selector: row => row.name,
            },
            {
                name: 'Website',
                selector: row => row.website,
            },
            {
                name: 'Điểm',
                selector: row => row.usePoint,
            },
            {
                name: 'Dùng điểm P',
                selector: row => row.isUsePointP,
                cell: (row) => {
                    return (
                        <div>
                            <input
                                style={{ width: '100%' }} type="checkbox"
                                defaultChecked={row.isUsePointP} disabled />
                        </div>
                    )
                },
            },
            {
                name: 'Dùng điểm G',
                selector: row => row.isUsePointG,
                cell: (row) => {
                    return (
                        <div>
                            <input
                                style={{ width: '100%' }} type="checkbox"
                                defaultChecked={row.isUsePointG} disabled />
                        </div>
                    )
                },
            },
            {
                name: 'Action',
                selector: rows => rows,
                cell: (row) => {
                    return (
                        <div>
                            <a onClick={() => this.openModal(row)}
                                className="btn btn-primary shadow btn-xs sharp me-1"><i className="fas fa-pencil-alt"
                                ></i></a>
                        </div>
                    )
                },
            },
        ];
        return (
            <div className="main-content">
                <SupplierModal
                    isOpenModal={this.state.isOpenModal}
                    toggleFromParent={this.toggleSupplierModal}
                    titleModal={this.state.titleModal}
                    save={this.save}
                />
                <ReactNotifications />
                <div className="page-content">
                    <div className="content-body">
                        <div className="container-fluid">
                            <div className="page-titles">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <ol className="breadcrumb">
                                            {/* <li className="breadcrumb-item"><a >Supplier</a></li> */}
                                            <li className="breadcrumb-item"><a >Quản lý nguồn</a></li>
                                        </ol>
                                    </div>
                                    <div className='col-lg-5'>
                                    </div>
                                    <div className='col-lg-3'>
                                        <input type="text" className="form-control" placeholder="Nhập chuỗi cần tìm"
                                            onChange={(event) => this.onChangeInput(event, "email")}
                                            value={this.state.email} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-xl-12 col-lg-12">
                                    <div className='card'>
                                        <div className="card-body">
                                            <DataTable
                                                className='dartTable'
                                                data-tag="allowRowEvents"
                                                columns={columns}
                                                data={listData}
                                                striped={true}

                                                pagination
                                                paginationComponentOptions={paginationComponentOptions}
                                                paginationServer={true}
                                                paginationTotalRows={this.state.totalCount}
                                                paginationRowsPerPageOptions={[10,20,50,100]}
                                                onChangePage={(page) => {
                                                    this.onPageChange(0,page)
                                                }}
                                                onChangeRowsPerPage={(pageSize, pageNum) => {
                                                    this.onPageChange(pageSize, pageNum)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SupplierManage);
