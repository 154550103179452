import axios from '../axios';



export const getCurrentInfo = async () => {
    try {
        var res = await axios.get(`/User/GetCurrentInfo`)
        return res;
    } catch (error) {
        return 0;
    }
};
export const login = async (loginModel) => {
    try {
        var res = await axios.post(`/User/SignIn`, loginModel)
        if (res.isSuccess) {
            if (axios.defaults.headers['Authorization']){
                axios.defaults.headers['Authorization'] = res.data.token ? 'Bearer ' + res.data.token : "";
            }
            else {
                axios.defaults.headers = { 'Authorization': 'Bearer ' + res.data.token  }
            }
            
            return res;
        }
        else {
            return res;
        }
    } catch (error) {
        return 0;
    }
};
export const update = async (user) => {
    try {
        var res = await axios.post(`/User/Update`, user)
        return res;
    } catch (error) {
        return 0;
    }
};
export const create = async (user) => {
    try {
        var res = await axios.post(`/User/Create`, user)
        return res;
    } catch (error) {
        return 0;
    }
};
export const getListUser = async (filterModel) => {
    try {
        var res = await axios.get(`/User/GetListUser`, {
            params: {
                searchText: filterModel.searchText,
                pageNum: filterModel.pageNum,
                pageSize: filterModel.pageSize,
            }
        })
        return res;
    } catch (error) {
        return 0;
    }
};
export const deleteUser = async (model) => {
    try {
        var res = await axios.post(`/User/Delete`, model)
        return res;
    } catch (error) {
        return 0;
    }
};
