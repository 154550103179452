export const path = {
    HOME: '/',
    LOGIN: '/login',
    REGISTER: '/register',
    LOG_OUT: '/logout',
    SYSTEM: '/system'
};

export const languages = {
    VI: 'vi',
    EN: 'en'
};
 
export const manageActions = {
    ADD: "ADD",
    EDIT: "EDIT",
    DELETE: "DELETE"
};

export const dateFormat = {
    SEND_TO_SERVER: 'DD/MM/YYYY'
};

export const YesNoObj = {
    YES: 'Y',
    NO: 'N'
}
export const image = {
    logo: 'logo.png'
}

export const notify = {
    container: 'top-right',
    duration: 3000
}
export const url = {
    // url: "http://localhost:2279/api",
    url: "https://api.muastocks.com/api",
    imageUrl: "https://api.muastocks.com",
}