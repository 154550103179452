import React, { Component } from 'react';
import { emitter } from "../../utils/emitter";
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import * as messageService from "../../services/messageService"
import * as uploadService from "../../services/uploadService";
import * as constant from '../../utils/constant'



class NewModal extends Component {
    fooRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            code: '',
            name: '',
            detail: '',
            imageUrl: '',
            description: '',
            isPromotion: false,
            file: null,
            fileName: '', 
            link: ''
        }
        this.listenEmitter();
    }
    componentDidUpdate () {
        setTimeout(
            function () {
                if (this.state.fileName && this.fooRef.current) {
                    let fileName = this.state.fileName.slice(this.state.fileName.lastIndexOf("/") + 1,this.state.fileName.search(".png"))
                    if (fileName.search(".") == -1){
                        fileName = fileName + ".png"
                    }
                    let file = new File([""], fileName, { type: "image/jpeg", lastModified: new Date().getTime() }, 'utf-8');
                    let container = new DataTransfer();
                    container.items.add(file);
                    document.querySelector('#file_input').files = container.files;
                }
                else {
                    document.querySelector('#file_input').files = null;
                }
            }
                .bind(this),
            100
        );
    }
    createFileList = (file) => {
        let fileList = new FileList();
        fileList[0] = file;
        fileList.item = index => fileList[index]; // override method functionality
        return fileList;
    }
    listenEmitter = () => {
        emitter.on("CLEAR_DATA_MODAL", () => {
            this.setState({
                id: 0,
                code: '',
                name: '',
                detail: '',
                imageUrl: '',
                description: '',
                isPromotion: false,
                file: null,
                fileName: '',
                link: ''
            })
        })
        emitter.on("DATA_MODAL", async (data) => {
            let copyState = { ...this.state };
            copyState = data;
            this.setState({
                ...copyState
            })
            if (data.imageUrl) {
                this.setState({
                    fileName: data.imageUrl
                })
            }
            else {
                this.setState({
                    fileName: ''
                })
            }
        })
    }
    handleChangeChk = (event, id) => {
        let copyState = { ...this.state };
        copyState[id] = event.target.checked;
        this.setState({
            ...copyState
        })
    }
    onChangeInput = (event, id) => {
        let copyState = { ...this.state };
        copyState[id] = event.target.value;
        this.setState({
            ...copyState
        })
    }
    handleImg = (event) => {
        if (event.target.files[0]) {
            this.setState({
                file: event.target.files[0]
            })
        }
        else {
            this.setState({
                file: null
            })
        }
    }
    toggle = () => {
        this.props.toggleFromParent();
    }
    onSubmit = async () => {
        if (!this.isValidData()) return;
        this.setState({
            code: this.state.name
        },
            () => {
                this.props.save(this.state);
            })
    }
    isValidData = () => {
        let arr = ["name", "detail", "description"]
        let arrS = ["tên", "nội dung", "mô tả"]
        for (let i = 0; i < arr.length; i++) {
            if (!this.state[arr[i]]) {
                messageService.messageNotify('', ('Vui lòng nhập ' + arrS[i]), 'warning');
                return false;
            }
        }
        return true;
    }
    render() {

        return (
            <>
                <Modal
                    isOpen={this.props.isOpenModal}
                    toggle={() => { this.toggle() }}
                    className={"modal-lg"}
                >
                    <ModalHeader toggle={() => { this.toggle() }}>{this.props.titleModal}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="mb-1 col-md-6">
                                <label className="form-label">Tên</label>
                                <input type="text" className="form-control" placeholder="nhập tên"
                                    onChange={(event) => this.onChangeInput(event, "name")}
                                    value={this.state.name} />
                            </div>
                            <div className="mb-1 col-md-6">
                                <label className="form-label">Hình ảnh</label>
                                <input
                                    ref={this.fooRef}
                                    type="file" className="form-control" placeholder="Chọn hình"
                                    onChange={(event) => this.handleImg(event)}
                                    id="file_input"
                                />
                            </div>
                            <div className="mb-1 col-md-9">
                                <label className="form-label">Link</label>
                                <input type="text" className="form-control" placeholder="nhập link"
                                    onChange={(event) => this.onChangeInput(event, "link")}
                                    value={this.state.link} />
                            </div>
                            <div className="mb-1 col-md-3">
                                <label className="form-label" style={{ width: '100%' }}>Khuyến mãi</label>
                                <input
                                    style={{ width: '10%', float: 'left' }} type="checkbox"
                                    defaultChecked={this.state.isPromotion}
                                    onChange={(event) => this.handleChangeChk(event, 'isPromotion')} />
                            </div>
                            <div className="mb-1 col-md-12">
                                <label className="form-label">Mô tả</label>
                                <textarea className="form-control" id="txtArea" rows="2" placeholder="nhập mô tả"
                                    onChange={(event) => this.onChangeInput(event, "description")}
                                    value={this.state.description}
                                ></textarea>
                            </div>
                            <div className="mb-1 col-md-12">
                                <label className="form-label">Nội dung</label>
                                <textarea className="form-control" id="txtAreaa" rows="12" placeholder="nhập nội dung"
                                    onChange={(event) => this.onChangeInput(event, "detail")}
                                    value={this.state.detail}
                                ></textarea>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-xs' onClick={() => this.onSubmit()} color="primary" >
                            <i className="fas fa-save"></i> Lưu
                        </Button>{' '}
                        <Button className='btn-xs' onClick={() => this.toggle()} color="danger" >
                            <i className="fas fa-close"></i>  Hủy
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewModal);
