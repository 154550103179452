import axios from '../axios';


export const update = async (user) => {
    try {
        var res = await axios.post(`/Notification/Update`, user)
        return res;
    } catch (error) {
        return 0;
    }
};
export const create = async (user) => {
    try {
        var res = await axios.post(`/Notification/Create`, user)
        return res;
    } catch (error) {
        return 0;
    }
};
export const get = async (_id) => {
    try {
        var res = await axios.get(`/Notification/Get`, {
            params: {
                id: _id
            }
        })
        return res;
    } catch (error) {
        return 0;
    }
};
export const deleteNew = async (model) => {
    try {
        var res = await axios.post(`/Notification/Delete`, model)
        return res;
    } catch (error) {
        return 0;
    }
};
