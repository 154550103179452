import axios from '../axios';


export const uploadImageToSave = async (model) => {
    try {
        
        const formData = new FormData();
        formData.append("file",model.file);
        formData.append("folderName",model.folderName);
        formData.append("subFolder",model.subFolder);
        formData.append("code",model.code);

        var res = await axios.post(`/Upload/UploadImageToSave`, formData)
        return res;
    } catch (error) {
        return 0;
    }
};
export const getImageByPath = async (imagePath) => {
    try {
        var res = await axios.get(`/Upload/GetImageByPath`, {
            params: {
                imagePath: imagePath,
            }
        })
        return res;
    } catch (error) {
        return 0;
    }
};
