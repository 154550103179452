import React, { Component } from 'react';
import { connect } from 'react-redux';
import './User.scss';

import key from "../../assets/images/icon/key.png";
import edit from "../../assets/images/icon/edit.png";
import image from "../../assets/images/default/image.png";
import * as userService from "../../services/userService"
import * as messageService from "../../services/messageService"
import { ReactNotifications } from 'react-notifications-component'

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditPro: false,
            user: {
                email: "",
                name: "",
                lastName: "",
                phoneNumber: "",
                address: "",
                companyName: ""
            }
        }
    }
    componentDidMount() {
        this.getCurrentUser();
    }
    getCurrentUser = async () => {
        let res = await userService.getCurrentInfo();
        if (res == 0) {
            messageService.messageNotify('', 'Đã có lỗi xảy ra, vui lòng thử lại!', 'warning');
            return;
        }
        else if (res.isSuccess) {
            let copyState = { ...this.state.user };
            copyState = res.data;
            this.setState({
                user: copyState
            })
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
            return;
        }
    }
    editProduct = (isEdit) => {
        this.setState({
            isEditPro: isEdit
        })
    }
    /// edit
    onChangeInput = (event, id) => {
        let copyState = { ...this.state.user };
        copyState[id] = event.target.value;
        this.setState({
            user: copyState
        })
    }
    onSubmit = async () => {
        if (!this.isValidData()) return;
        let res = await userService.update(this.state.user);
        if (res == 0) {
            messageService.messageNotify('', 'Đã có lỗi xảy ra, vui lòng thử lại!', 'warning');
        }
        else if (res.isSuccess) {
            messageService.messageNotify('', 'Edit success', 'success');
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
        }
    }
    isValidData = () => {
        let arr = ["email", "name", "lastName", "phoneNumber", "address", "companyName"]
        for (let i = 0; i < arr.length; i++) {
            if (!this.state.user[arr[i]]) {
                messageService.messageNotify('', ('Please enter your ' + arr[i]), 'warning');
                return false;
            }
        }
        return true;
    }
    render() {
        return (
            <div className='user-manage'>
                <ReactNotifications />
                <div className='container'>
                    <div className='user-header'>
                        <h1>My Profile</h1>
                        <p style={{ color: '#837F7E' }}>Thank you choose us services</p>
                    </div>
                    <div className='user-content'>
                        <div className='user-top'>
                            <div className='right'>
                                <img src={key} />
                                <img onClick={() => this.editProduct(true)} src={edit} />
                            </div>
                        </div>
                        <div className='user-info'>
                            <div className='row'>
                                <div className='user-img col-lg-3'>
                                    <img src={image} />
                                </div>
                                <div className='user-detail col-lg-9'>
                                    <div className=''>
                                        <label>Email</label>
                                        <h4>{this.state.user.email}</h4>
                                        <div className='row'>
                                            <div className='col-lg-4'>
                                                <label>First Name</label>
                                                <h4 className={this.state.isEditPro ? 'hide' : 'show'}>{this.state.user.name}</h4>
                                                <input onChange={(event) => this.onChangeInput(event, "firstName")}
                                                    className={this.state.isEditPro ? 'show' : 'hide'}
                                                    value={this.state.user.name} />
                                            </div>
                                            <div className='col-lg-4'>
                                                <label>Last Name</label>
                                                <h4 className={this.state.isEditPro ? 'hide' : 'show'}>{this.state.user.lastName}</h4>
                                                <input onChange={(event) => this.onChangeInput(event, "lastName")}
                                                    className={this.state.isEditPro ? 'show' : 'hide'}
                                                    value={this.state.user.lastName} />
                                            </div>
                                            <div className='col-lg-4'>
                                                <label>Phone Number</label>
                                                <h4 className={this.state.isEditPro ? 'hide' : 'show'}>+{this.state.user.phoneNumber}</h4>
                                                <input 
                                                    type="text"
                                                    onChange={(event) => this.onChangeInput(event, "phoneNumber")}
                                                    className={this.state.isEditPro ? 'show' : 'hide'}
                                                    value={this.state.user.phoneNumber} />
                                            </div>
                                        </div>
                                        <label>Address</label>
                                        <h4 className={this.state.isEditPro ? 'hide' : 'show'}>{this.state.user.address}</h4>
                                        <input onChange={(event) => this.onChangeInput(event, "address")}
                                            className={this.state.isEditPro ? 'show' : 'hide'}
                                            value={this.state.user.address} />
                                        <div>
                                            <label>Company</label>
                                            <h4 className={this.state.isEditPro ? 'hide' : 'show'}>{this.state.user.companyName}</h4>
                                            <input onChange={(event) => this.onChangeInput(event, "companyName")}
                                                className={this.state.isEditPro ? 'show' : 'hide'}
                                                value={this.state.user.companyName} />
                                        </div>
                                    </div>
                                    <div className={!this.state.isEditPro ? 'hide user-bottom' : 'show user-bottom'} >
                                        <button onClick={() => this.onSubmit()} className='btn-user btn-orange'>SAVE CHANGE</button>
                                        <button onClick={() => this.editProduct(false)} className='btn-user btn-gray'>CANCEL</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(User);
