import axios from 'axios';
import * as constant from '../src/utils/constant'

var token = "";
var header = "";
var user
var rs = localStorage.getItem("persist:user");
var rss = JSON.parse(rs);
if (rss) { user = JSON.parse(rss.userInfo); }
if (user) {
    token = user.token;
    header = { 'Authorization': 'Bearer ' + token }
}
else {
    header = { 'Authorization': 'Bearer ' + "" }
}
const instance = axios.create({
    
    baseURL: constant.url.url,
    // baseURL: "https://api.muastocks.com/api",
    withCredentials: true,
    headers: header
});

// const createError = (httpStatusCode, statusCode, errorMessage, problems, errorCode = '') => {
//     const error = new Error();
//     error.httpStatusCode = httpStatusCode;
//     error.statusCode = statusCode;
//     error.errorMessage = errorMessage;
//     error.problems = problems;
//     error.errorCode = errorCode + "";
//     return error;
// };

// export const isSuccessStatusCode = (s) => {
//     // May be string or number
//     const statusType = typeof s;
//     return (statusType === 'number' && s === 0) || (statusType === 'string' && s.toUpperCase() === 'OK');
// };
// instance.interceptors.request.use(
//     function (config) {

//         var token = "";
//         var rs = localStorage.getItem("persist:user");
//         var rss = JSON.parse(rs);
//         var user = JSON.parse(rss.userInfo);
//         if (user) {
//             token = user.token;
//         }
//         config.headers.Authorization = token ? 'Bearer ' + token : "";
//         return config;
//     });
instance.interceptors.response.use(
    (response) => {
        // Thrown error for request with OK status code
        // if (data.hasOwnProperty('s') && !isSuccessStatusCode(data['s']) && data.hasOwnProperty('errmsg')) {
        //     return Promise.reject(createError(response.status, data['s'], data['errmsg'], null, data['errcode'] ? data['errcode'] : ""));
        // }

        // // Return direct data to callback
        // if (data.hasOwnProperty('s') && data.hasOwnProperty('d')) {
        //     return data['d'];
        // }
        // // Handle special case
        // if (data.hasOwnProperty('s') && _.keys(data).length === 1) {
        //     return null;
        // }
        return response.data;
    },
    // (error) => {
    //     const { response } = error;
    //     if (response == null) {
    //         return Promise.reject(error);
    //     }

    //     const { data } = response;

    //     if (data.hasOwnProperty('s') && data.hasOwnProperty('errmsg')) {
    //         return Promise.reject(createError(response.status, data['s'], data['errmsg']));
    //     }

    //     if (data.hasOwnProperty('code') && data.hasOwnProperty('message')) {
    //         return Promise.reject(createError(response.status, data['code'], data['message'], data['problems']));
    //     }

    //     return Promise.reject(createError(response.status));
    // }
);

export default instance;
