import axios from '../axios';



export const getCurrentInfo = async () => {
    try {
        var res = await axios.get(`/Client/GetCurrentInfo`)
        return res;
    } catch (error) {
        return 0;
    }
};
export const update = async (model) => {
    try {
        var res = await axios.post(`/Client/Update`, model)
        return res;
    } catch (error) {
        return 0;
    }
};
export const getListClient = async (filterModel) => {
    try {
        var res = await axios.get(`/Client/getListClient`, {
            params: {
                searchText: filterModel.searchText,
                pageNum: filterModel.pageNum,
                pageSize: filterModel.pageSize,
            }
        })
        return res;
    } catch (error) {
        return 0;
    }
};
export const create = async (user) => {
    try {
        var res = await axios.post(`/Client/Create`, user)
        return res;
    } catch (error) {
        return 0;
    }
};
export const deleteClient = async (model) => {
    try {
        var res = await axios.post(`/Client/Delete`, model)
        return res;
    } catch (error) {
        return 0;
    }
};
export const addPoint = async (model) => {
    try {
        var res = await axios.post(`/Client/AddPoint`, model)
        return res;
    } catch (error) {
        return 0;
    }
};

export const getAddPointHistory = async (filterModel) => {
    try {
        var res = await axios.get(`/Client/GetAddPointHistory`, {
            params: {
                searchText: filterModel.searchText,
                pageNum: filterModel.pageNum,
                pageSize: filterModel.pageSize,
            }
        })
        return res;
    } catch (error) {
        return 0;
    }
};
export const getDownloadHistory = async (filterModel) => {
    try {
        var res = await axios.get(`/Client/GetDownloadHistory`, {
            params: {
                searchText: filterModel.searchText,
                pageNum: filterModel.pageNum,
                pageSize: filterModel.pageSize,
            }
        })
        return res;
    } catch (error) {
        return 0;
    }
};
