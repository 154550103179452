import React, { Component } from 'react';
import { connect } from 'react-redux';
import './BalanceManage.scss';

import * as supplierService from "../../services/supplierService";
import * as messageService from "../../services/messageService"


class BalanceManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            filterModel: {
                searchText: '',
                pageNum: 1,
                pageSize: 10,
            },
        }

    }

    componentDidMount() {
        this.getBalances();
    }
    getBalances = async () => {
        let res = await supplierService.getListBalance(this.state.filterModel);
        if (res == 0) {
            messageService.messageNotify('', 'Đã có lỗi xảy ra, vui lòng thử lại!', 'warning');
            return;
        }
        else if (res.isSuccess) {
            let copyState = { ...this.state.data };
            copyState = res.data;
            this.setState({
                data: copyState
            })
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
            return;
        }
    }
    render() {
        let data = this.state.data ? this.state.data : {
            bBonus: 0,
            bValue: 0
        }
        return (
            <div className="main-content">
                <div className="page-content">
                    <div className="content-body">
                        <div className="container-fluid">
                            <div className="page-titles">
                                <div className='row'>
                                    <div className='col-lg-2'>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a >Xem số dư:</a></li>
                                        </ol>
                                    </div>
                                    <div className='col-lg-5'>
                                        <h2 style={{ color: '#d3d341' }}>Điểm P: {data.bBonus}</h2>
                                        <br></br>
                                        <h2 style={{ color: '#2ac8c1' }}>Điểm G: {data.bValue}</h2>
                                    </div>
                                    <div className='col-lg-2'>
                                    </div>
                                    <div className='col-lg-3'>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(BalanceManage);
