import React, { Component } from 'react';
import { emitter } from "../../utils/emitter";
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as valid from '../../utils/validate'


import * as messageService from "../../services/messageService"



class SupplierModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            code: '',
            name: '',
            website: '',
            usePoint: '',
            isUsePointP: '',
            isUsePointG: '',
            isAI: '',
            isAIV: '',
            isEPS: '',
            isJPG: '',
            isMOV: '',
            isMP3: '',
            isPNG: '',
            isPSD: '',
            isSVG: '',
            isTIF: '',
            isZIP: ''
        }
        this.listenEmitter();
    }
    componentDidMount() {

    }
    listenEmitter = () => {
        emitter.on("DATA_MODAL", (data) => {
            this.setState({
                id: data.id,
                code: data.code,
                name: data.name,
                website: data.website,
                usePoint: data.usePoint,
                isUsePointP: data.isUsePointP,
                isUsePointG: data.isUsePointG,
                isAI: data.isAI,
                isAIV: data.isAIV,
                isEPS: data.isEPS,
                isJPG: data.isJPG,
                isMOV: data.isMOV,
                isMP3: data.isMP3,
                isPNG: data.isPNG,
                isPSD: data.isPSD,
                isSVG: data.isSVG,
                isTIF: data.isTIF,
                isZIP: data.isZIP,
            })
        })
    }
    handleChangeChk = (event, id) => {
        let copyState = { ...this.state };
        copyState[id] = event.target.checked;
        this.setState({
            ...copyState
        })
    }
    onChangeInput = (event, id) => {
        let copyState = { ...this.state };
        copyState[id] = event.target.value;
        this.setState({
            ...copyState
        })
    }
    toggle = () => {
        this.props.toggleFromParent();
    }
    onSubmit = async () => {
        if (!this.isValidData()) return;
        this.props.save(this.state);
    }
    isValidData = () => {
        let arr = ["code", "name", "website", "usePoint"]
        let arrVN = ["mã", "tên", "website", "điểm"]
        for (let i = 0; i < arr.length; i++) {
            if (!this.state[arr[i]]) {
                messageService.messageNotify('', ('Please enter ' + arrVN[i]), 'warning');
                return false;
            }
        }
        return true;
    }
    render() {

        let isShow = this.state.isShowPass
        return (
            <>
                <Modal
                    isOpen={this.props.isOpenModal}
                    toggle={() => { this.toggle() }}
                    className={"modal-lg"}
                >
                    <ModalHeader toggle={() => { this.toggle() }}>{this.props.titleModal}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="mb-1 col-md-6">
                                <label className="form-label">Tên</label>
                                <input type="text" className="form-control" placeholder="tên"
                                    onChange={(event) => this.onChangeInput(event, "name")}
                                    value={this.state.name} />
                            </div>
                            <div className="mb-1 col-md-6">
                                <label className="form-label">Mã</label>
                                <input type="text" className="form-control" placeholder="mã"
                                    onChange={(event) => this.onChangeInput(event, "code")}
                                    value={this.state.code} />
                            </div>
                            <div className="mb-1 col-md-6">
                                <label className="form-label">Website</label>
                                <input type="text" className="form-control" placeholder="website"
                                    onChange={(event) => this.onChangeInput(event, "website")}
                                    value={this.state.website} />
                            </div>
                            <div className="mb-1 col-md-6">
                                <label className="form-label">Điểm</label>
                                <input type="text" className="form-control" placeholder="điểm"
                                    onChange={(event) => this.onChangeInput(event, "usePoint")}
                                    value={this.state.usePoint} />
                            </div>
                            <div className="mb-1 col-md-6">
                                <label style={{ width: '100%' }} className="form-label">Dùng điểm P</label>
                                <input style={{ width: '10%' }} type="checkbox" defaultChecked={this.state.isUsePointP} onChange={(event) => this.handleChangeChk(event, 'isUsePointP')} />
                                {/* <input type="checkbox" className="form-control" placeholder="điểm"
                                    onChange={(event) => this.onChangeInput(event, "usePoint")}
                                    value={this.state.usePoint} /> */}
                            </div>
                            <div className="mb-1 col-md-6">
                                <label style={{ width: '100%' }} className="form-label">Dùng điểm G</label>
                                <input style={{ width: '10%' }} type="checkbox" defaultChecked={this.state.isUsePointG} onChange={(event) => this.handleChangeChk(event, 'isUsePointG')} />
                            </div>
                            <div className="mb-1 col-md-12">
                                <label className="form-label">Hỗ trợ định dạng</label>
                            </div>
                            <div className="mb-1 col-md-2">
                                <input style={{ width: '10%' }} type="checkbox" defaultChecked={this.state.isAI} 
                                onChange={(event) => this.handleChangeChk(event, 'isAI')} /> AI
                            </div>
                            <div className="mb-1 col-md-2">
                                <input style={{ width: '10%' }} type="checkbox" defaultChecked={this.state.isAIV} 
                                onChange={(event) => this.handleChangeChk(event, 'isAIV')} /> AIV
                            </div>
                            <div className="mb-1 col-md-2">
                                <input style={{ width: '10%' }} type="checkbox" defaultChecked={this.state.isEPS} 
                                onChange={(event) => this.handleChangeChk(event, 'isEPS')} /> EPS
                            </div>
                            <div className="mb-1 col-md-2">
                                <input style={{ width: '10%' }} type="checkbox" defaultChecked={this.state.isJPG} 
                                onChange={(event) => this.handleChangeChk(event, 'isJPG')} /> JPG
                            </div>
                            <div className="mb-1 col-md-2">
                                <input style={{ width: '10%' }} type="checkbox" defaultChecked={this.state.isMOV} 
                                onChange={(event) => this.handleChangeChk(event, 'isMOV')} /> MOV
                            </div>
                            <div className="mb-1 col-md-2">
                                <input style={{ width: '10%' }} type="checkbox" defaultChecked={this.state.isMP3} 
                                onChange={(event) => this.handleChangeChk(event, 'isMP3')} /> MP3
                            </div>
                            <div className="mb-1 col-md-2">
                                <input style={{ width: '10%' }} type="checkbox" defaultChecked={this.state.isPNG} 
                                onChange={(event) => this.handleChangeChk(event, 'isPNG')} /> PNG
                            </div>
                            <div className="mb-1 col-md-2">
                                <input style={{ width: '10%' }} type="checkbox" defaultChecked={this.state.isPSD} 
                                onChange={(event) => this.handleChangeChk(event, 'isPSD')} /> PSD
                            </div>
                            <div className="mb-1 col-md-2">
                                <input style={{ width: '10%' }} type="checkbox" defaultChecked={this.state.isSVG} 
                                onChange={(event) => this.handleChangeChk(event, 'isSVG')} /> SVG
                            </div>
                            <div className="mb-1 col-md-2">
                                <input style={{ width: '10%' }} type="checkbox" defaultChecked={this.state.isTIF} 
                                onChange={(event) => this.handleChangeChk(event, 'isTIF')} /> TIF
                            </div>
                            <div className="mb-1 col-md-2">
                                <input style={{ width: '10%' }} type="checkbox" defaultChecked={this.state.isZIP} 
                                onChange={(event) => this.handleChangeChk(event, 'isZIP')} /> ZIP
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-xs' onClick={() => this.onSubmit()} color="primary" >
                            <i className="fas fa-save"></i> Lưu
                        </Button>{' '}
                        <Button className='btn-xs' onClick={() => this.toggle()} color="danger" >
                            <i className="fas fa-close"></i>  Hủy
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SupplierModal);
