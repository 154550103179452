import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Dashboard.scss';


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <>
                DashboardDashboardDashboard
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
