import React, { Component } from 'react';
import { connect } from 'react-redux';
import './DowloadHistory.scss';

import * as clientService from "../../services/clientService";
import * as messageService from "../../services/messageService"
import { ReactNotifications } from 'react-notifications-component';
import DataTable from 'react-data-table-component';
import moment from 'moment/moment';


class DowloadHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            filterModel: {
                searchText: '',
                pageNum: 1,
                pageSize: 10,
            },
            totalCount: 0,
            isOpenModal: false,
            titleModal: '',
            searchText: '',
        }

    }

    componentDidMount() {
        this.getData();
    }
    onChangeInput = (event) => {
        let copyState = { ...this.state.filterModel };
        copyState.searchText = event.target.value;
        this.setState({
            filterModel: copyState
        }, () => {
            this.getData();
        })
    }
    getData = async () => {
        let res = await clientService.getDownloadHistory(this.state.filterModel);
        if (res == 0) {
            messageService.messageNotify('', 'Đã có lỗi xảy ra, vui lòng thử lại!', 'warning');
            return;
        }
        else if (res.isSuccess) {
            let copyState = { ...this.state.listData };
            copyState = res.data;
            this.setState({
                listData: copyState,
                totalCount: res.data[0] ? res.data[0].totalCount : 0
            })
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
            return;
        }
    }
    onPageChange = (pageSize, pageNum) => {
        this.state.filterModel.pageNum = pageNum;
        if (pageSize != 0) this.state.filterModel.pageSize = pageSize;
        this.getData();
    }
    render() {
        let listData = this.state.listData
        const paginationComponentOptions = {
            rowsPerPageText: "Số dòng",
            rangeSeparatorText: "của"
        };
        const columns = [
            {
                name: 'STT',
                selector: (row, index) => index + 1,
            },
            {
                name: 'Khách hàng',
                selector: row => row.cLientName,
            },
            {
                name: 'Ngày tải',
                selector: row => row.createdWhen,
                format: (row) => moment(row.createdWhen).format('HH:mm  DD/MM/yyyy'),
            },
            {
                name: 'Link',
                selector: row => row.link,
            },
            {
                name: 'Điểm dùng',
                selector: row => row.usePoint,
            },
            {
                name: 'Dùng điểm P',
                // selector: row => row.isUseP,
                cell: (row) => {
                    return (
                        <div>
                            <input
                                style={{ width: '100%' }} type="checkbox"
                                defaultChecked={row.isUseP} disabled />
                        </div>
                    )
                },
            },
        ];
        return (
            <div className="main-content">
                <ReactNotifications />
                <div className="page-content">
                    <div className="content-body">
                        <div className="container-fluid">
                            <div className="page-titles">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a >Lịch sử tải </a></li>
                                        </ol>
                                    </div>
                                    <div className='col-lg-3'>
                                    </div>
                                    <div className='col-lg-2'>
                                    </div>
                                    <div className='col-lg-3'>
                                        <input type="text" className="form-control" placeholder="Tìm kiếm..."
                                            onChange={(event) => this.onChangeInput(event)}
                                            value={this.state.filterModel.searchText} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-xl-12 col-lg-12">
                                    <div className='card'>
                                        <div className="card-body">
                                            <DataTable
                                                className='dartTable'
                                                data-tag="allowRowEvents"
                                                columns={columns}
                                                data={listData}
                                                striped={true}
                                                noDataComponent={"Không tìm thấy dữ liệu"}

                                                pagination
                                                paginationComponentOptions={paginationComponentOptions}
                                                paginationServer={true}
                                                paginationTotalRows={this.state.totalCount}
                                                paginationRowsPerPageOptions={[10, 20, 50, 100]}
                                                onChangePage={(page) => {
                                                    this.onPageChange(0, page)
                                                }}
                                                onChangeRowsPerPage={(pageSize, pageNum) => {
                                                    this.onPageChange(pageSize, pageNum)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DowloadHistory);
