import axios from '../axios';


export const update = async (user) => {
    try {
        var res = await axios.post(`/News/Update`, user)
        return res;
    } catch (error) {
        return 0;
    }
};
export const create = async (user) => {
    try {
        var res = await axios.post(`/News/Create`, user)
        return res;
    } catch (error) {
        return 0;
    }
};
export const getListNew = async (filterModel) => {
    try {
        var res = await axios.get(`/News/GetListNews`, {
            params: {
                searchText: filterModel.searchText,
                pageNum: filterModel.pageNum,
                pageSize: filterModel.pageSize,
            }
        })
        return res;
    } catch (error) {
        return 0;
    }
};
export const deleteNew = async (model) => {
    try {
        var res = await axios.post(`/News/Delete`, model)
        return res;
    } catch (error) {
        return 0;
    }
};
