import React, { Component } from 'react';
import { connect } from 'react-redux';
import './NotiManage.scss';
import { Button } from 'reactstrap';
import { ReactNotifications } from 'react-notifications-component';

import * as notiService from "../../services/notiService";
import * as messageService from "../../services/messageService"


class NotiManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noti: '',
            data: '',
            id: 1,
        }

    }

    componentDidMount() {
        this.getNoti();
    }
    getNoti = async () => {
        let res = await notiService.get(this.state.id);
        if (res == 0) {
            messageService.messageNotify('', 'Đã có lỗi xảy ra, vui lòng thử lại!', 'warning');
            return;
        }
        else if (res.isSuccess) {
            let copyState = { ...this.state.data };
            copyState = res.data;
            this.setState({
                noti: copyState.name,
                data: copyState
            })
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
            return;
        }
    }
    onChangeInput = (event, id) => {
        let copyState = { ...this.state };
        copyState.noti = event.target.value;
        this.setState({
            ...copyState
        })
    }
    onSubmit = async () => {
        //
        this.state.data.name = this.state.noti;
        let res;
        res = await notiService.update(this.state.data);
        if (res == 0) {
            messageService.messageNotify('', 'Đã có lỗi xảy ra, vui lòng thử lại!', 'warning');
            return;
        }
        else if (res.isSuccess) {
            messageService.messageNotify('', 'Cập nhật dữ liệu thành công', 'success');
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
            return;
        }
    }
    render() {
        let data = this.state.data ? this.state.data : {
            bBonus: 0,
            bValue: 0
        }
        return (
            <div className="main-content">
            <ReactNotifications />
                <div className="page-content">
                    <div className="content-body">
                        <div className="container-fluid">
                            <div className="page-titles">
                                <div className='row'>
                                    <div className='col-lg-2'>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a >Thông báo:</a></li>
                                        </ol>
                                    </div>
                                    <div className='col-lg-8'>
                                        <input type="text" className="form-control" placeholder="Thông báo"
                                            onChange={(event) => this.onChangeInput(event, "name")}
                                            value={this.state.noti} />
                                    </div>
                                    <div className='col-lg-2'>
                                        <Button className='btn-xs' onClick={() => this.onSubmit()} color="primary" >
                                            <i className="fas fa-save"></i> Lưu
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(NotiManage);
