import React, { Component } from 'react';
import { emitter } from "../../utils/emitter";
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as valid from '../../utils/validate'


import * as messageService from "../../services/messageService"



class ClientModalPoint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientId: 0,
            pointP: '',
            pointG: '',
            isShowPass: true
        }
        this.listenEmitter();
    }
    componentDidMount() {

    }
    listenEmitter = () => {
        emitter.on("CLEAR_DATA_MODAL_POINT", (data) => {
            this.setState({
                clientId: data?.id,
                pointP: '',
                pointG: '',
            })
        })
    }
    onChangeInput = (event, id) => {
        let copyState = { ...this.state };
        copyState[id] = event.target.value;
        this.setState({
            ...copyState
        })
    }
    toggle = () => {
        this.props.togglePointFromParent();
    }
    onSubmit = async () => {
        if (!this.isValidData()) return;
        this.props.savePoint(this.state);
    }
    isValidData = () => {
        let arr = ["pointP", "pointG"]
        for (let i = 0; i < arr.length; i++) {
            if (!this.state[arr[i]]) {
                messageService.messageNotify('', ('Please enter ' + arr[i]), 'warning');
                return false;
            }
        }
        return true;
    }
    render() {
        return (
            <>
                <Modal
                    isOpen={this.props.isOpenModalPoint}
                    toggle={() => { this.toggle() }}
                    className={"modal-lg"}
                >
                    <ModalHeader toggle={() => { this.toggle() }}>Thêm điểm</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="mb-1 col-md-6">
                                <label className="form-label">Điểm P</label>
                                <input type="text" className="form-control" placeholder="Điểm P"
                                    onChange={(event) => this.onChangeInput(event, "pointP")}
                                    value={this.state.pointP} />
                            </div>
                            <div className="mb-1 col-md-6">
                                <label className="form-label">Điểm G</label>
                                <input type="text" className="form-control" placeholder="Điểm G"
                                    onChange={(event) => this.onChangeInput(event, "pointG")}
                                    value={this.state.pointG} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-xs' onClick={() => this.onSubmit()} color="primary" >
                            <i className="fas fa-save"></i> Lưu
                        </Button>{' '}
                        <Button className='btn-xs' onClick={() => this.toggle()} color="danger" >
                            <i className="fas fa-close"></i>  Hủy
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientModalPoint);
