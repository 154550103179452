import React, { Component } from 'react';
import { emitter } from "../../utils/emitter";
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as valid from '../../utils/validate'


import * as messageService from "../../services/messageService"



class ClientModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            code: '',
            name: '',
            lastName: '',
            email: '',
            address: '',
            companyName: '',
            phoneNumber: '',
            avatar: '',
            password: '',
            pointG:'',
            pointP:'',
            isShowPass: true
        }
        this.listenEmitter();
    }
    componentDidMount() {

    }
    listenEmitter = () => {
        emitter.on("CLEAR_DATA_MODAL", () => {
            this.setState({
                id: 0,
                code: '',
                name: '',
                lastName: '',
                email: '',
                address: '',
                companyName: '',
                phoneNumber: '',
                avatar: '',
                password: '',
                pointG:'',
                pointP:'',
            })
        })
        emitter.on("DATA_MODAL", (data) => {
            this.setState({
                id: data.id,
                code: data.code,
                name: data.name,
                lastName: data.lastName,
                email: data.email,
                address: data.address,
                companyName: data.companyName,
                phoneNumber: data.phoneNumber,
                avatar: data.avatar,
                password: '',
                pointG:data.pointG,
                pointP:data.pointP,
                isShowPass: false,
            })
        })
    }
    onChangeInput = (event, id) => {
        let copyState = { ...this.state };
        copyState[id] = event.target.value;
        this.setState({
            ...copyState
        })
    }
    toggle = () => {
        this.props.toggleFromParent();
    }
    onSubmit = async () => {
        if (!this.isValidData()) return;
        this.props.save(this.state);
    }
    isValidData = () => {
        let arr = ["email", "name"]
        for (let i = 0; i < arr.length; i++) {
            if (!this.state[arr[i]]) {
                messageService.messageNotify('', ('Vui lòng nhập ' + arr[i]), 'warning');
                return false;
            }
        }
        if (!valid.validateEmail(this.state.email)) {
            messageService.messageNotify('', 'Email không đúng định dạng', 'warning');
            return false;
        }
        return true;
    }
    render() {
        let isShow = this.state.isShowPass
        return (
            <>
                <Modal
                    isOpen={this.props.isOpenModal}
                    toggle={() => { this.toggle() }}
                    className={"modal-lg"}
                >
                    <ModalHeader toggle={() => { this.toggle() }}>{this.props.titleModal}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="mb-1 col-md-6">
                                <label className="form-label">Email</label>
                                <input type="text" className="form-control" placeholder="Email"
                                    onChange={(event) => this.onChangeInput(event, "email")}
                                    value={this.state.email} readOnly='true' />
                            </div>
                            <div className="mb-1 col-md-6">
                                <label className="form-label">Họ & tên</label>
                                <input type="text" className="form-control" placeholder="Họ & tên"
                                    onChange={(event) => this.onChangeInput(event, "name")}
                                    value={this.state.name} />
                            </div>
                            <div className="mb-1 col-md-6">
                                <label className="form-label">Số điện thoại</label>
                                <input type="text" className="form-control" placeholder="Phone Number"
                                    onChange={(event) => this.onChangeInput(event, "phoneNumber")}
                                    value={this.state.phoneNumber} />
                            </div>
                            <div className="mb-1 col-md-6" >
                                <label className="form-label">Mật khẩu</label>
                                <input
                                    value={this.state.password} id='password' name='password'
                                    type={'password'}
                                    onChange={(event) => this.onChangeInput(event, "password")}
                                    className="form-control pe-5 password-input" placeholder="Nhập password" />
                            </div>
                            <div className="mb-1 col-md-12">
                                <label className="form-label">Địa chỉ</label>
                                <input type="text" className="form-control" placeholder="Nhập địa chỉ"
                                    onChange={(event) => this.onChangeInput(event, "address")}
                                    value={this.state.address} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-xs' onClick={() => this.onSubmit()} color="primary" >
                            <i className="fas fa-save"></i> Lưu
                        </Button>{' '}
                        <Button className='btn-xs' onClick={() => this.toggle()} color="danger" >
                            <i className="fas fa-close"></i>  Hủy
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientModal);
