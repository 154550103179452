import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ClientManage.scss';
import { emitter } from "../../utils/emitter";

import * as clientService from "../../services/clientService";
import * as messageService from "../../services/messageService"
import { ReactNotifications } from 'react-notifications-component';
import ClientModal from './ClientModal';
import DataTable from 'react-data-table-component';
import ClientModalPoint from './ClientModalPoint';


class ClientManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            filterModel: {
                searchText: '',
                pageNum: 1,
                pageSize: 10,
            },
            isOpenModal: false,
            isOpenModalPoint: false,
            titleModal: '',
            isAdd: false,
            totalCount: 0,
        }

    }

    componentDidMount() {
        this.getClients();
    }
    onChangeInput = (event) => {
        let copyState = { ...this.state.filterModel };
        copyState.searchText =  event.target.value;
        this.setState({
            filterModel : copyState
        }, () => {
            this.getClients();
        })
    }
    getClients = async () => {
        let res = await clientService.getListClient(this.state.filterModel);
        if (res == 0) {
            messageService.messageNotify('', 'Đã có lỗi xảy ra, vui lòng thử lại!', 'warning');
            return;
        }
        else if (res.isSuccess) {
            let copyState = { ...this.state.listData };
            copyState = res.data;
            this.setState({
                listData: copyState,
                totalCount: res.data[0] ? res.data[0].totalCount : 0
            })
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
            return;
        }
    }
    toggleClientModal = () => {
        this.setState({
            isOpenModal: !this.state.isOpenModal
        })
    }
    toggleClientModalPoint = () => {
        this.setState({
            isOpenModalPoint: !this.state.isOpenModalPoint
        })
    }
    onPageChange = (pageSize, pageNum) => {
        this.state.filterModel.pageNum = pageNum;
        if (pageSize != 0) this.state.filterModel.pageSize = pageSize;
        this.getClients();
    }
    openModal(data) {
        if (!data) {
            emitter.emit("CLEAR_DATA_MODAL");
            this.setState({
                isOpenModal: true,
                titleModal: 'Add new user',
                isAdd: true
            }, () => {
            })

        }
        else {
            emitter.emit("DATA_MODAL", data);
            this.setState({
                isOpenModal: true,
                titleModal: 'Edit user',
                isAdd: false
            })
        }
    }
    openModalPoint(data) {
        emitter.emit("CLEAR_DATA_MODAL_POINT", data);
        this.setState({
            isOpenModalPoint: true
        })
    }
    save = async (data) => {
        let res;
        if (this.state.isAdd) {
            res = await clientService.create(data);
        }
        else {
            res = await clientService.update(data);
        }
        if (res == 0) {
            messageService.messageNotify('', 'Đã có lỗi xảy ra, vui lòng thử lại!', 'warning');
            return;
        }
        else if (res.isSuccess) {
            this.getClients();
            this.setState({
                isOpenModal: false
            })
            messageService.messageNotify('', 'Cập nhật thành công', 'success');
            emitter.emit("CLEAR_DATA_MODAL");
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
            return;
        }
    }
    savePoint = async (data) => {
        let res = await clientService.addPoint(data);
        if (res == 0) {
            messageService.messageNotify('', 'Đã có lỗi xảy ra, vui lòng thử lại!', 'warning');
            return;
        }
        else if (res.isSuccess) {
            this.getClients();
            this.setState({
                isOpenModalPoint: false
            })
            messageService.messageNotify('', 'Add point thành công', 'success');
            emitter.emit("CLEAR_DATA_MODAL_POINT");
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
            return;
        }
    }

    deleteData = async (data) => {
        let res = await clientService.deleteClient(data);
        if (res == 0) {
            messageService.messageNotify('', 'Đã có lỗi xảy ra, vui lòng thử lại!', 'warning');
            return;
        }
        else if (res.isSuccess) {
            this.getListClient();
            messageService.messageNotify('', 'Xóa thành công', 'success');
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
            return;
        }
    }
    render() {
        const paginationComponentOptions = {
            rowsPerPageText:"Số dòng",
            rangeSeparatorText:"của" 
        };
        let listData = this.state.listData
        const columns = [
            {
                name: 'STT.',
                selector: (row, index) => index + 1,
            },
            {
                name: 'Email',
                selector: row => row.email,
            },
            {
                name: 'Họ & tên',
                selector: row => row.name,
            },
            {
                name: 'Số điện thoại',
                selector: row => row.phoneNumber,
            },
            {
                name: 'Điểm P',
                selector: row => row.pointP,
            },
            {
                name: 'Điểm G',
                selector: row => row.pointG,
            },

            {
                name: 'Action',
                selector: rows => rows,
                cell: (row) => {
                    return (
                        <div>
                            <a onClick={() => this.openModalPoint(row)}
                                className="btn btn-success shadow btn-xs sharp me-1"><i className="fas fa-plus"
                                ></i></a>
                            <a onClick={() => this.openModal(row)}
                                className="btn btn-primary shadow btn-xs sharp me-1"><i className="fas fa-pencil-alt"
                                ></i></a>
                            <a onClick={() => this.deleteData(row)}
                                className="btn btn-danger shadow btn-xs sharp"><i className="fas fa-trash-alt"
                                ></i></a>
                        </div>
                    )
                },
            },
        ];
        return (
            <div className="main-content">
                <ClientModal
                    isOpenModal={this.state.isOpenModal}
                    toggleFromParent={this.toggleClientModal}
                    titleModal={this.state.titleModal}
                    save={this.save}
                />
                <ClientModalPoint
                    savePoint={this.savePoint}
                    togglePointFromParent={this.toggleClientModalPoint}
                    isOpenModalPoint={this.state.isOpenModalPoint}
                />
                <ReactNotifications />
                <div className="page-content">
                    <div className="content-body">
                        <div className="container-fluid">
                            <div className="page-titles">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a >Quản lý khách hàng</a></li>
                                        </ol>
                                    </div>
                                    <div className='col-lg-3'>
                                    </div>
                                    <div className='col-lg-2'>
                                    </div>
                                    <div className='col-lg-3'>
                                        <input type="text" className="form-control" placeholder="Tìm kiếm..."
                                            onChange={(event) => this.onChangeInput(event)}
                                            value={this.state.filterModel.searchText} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-xl-12 col-lg-12">
                                    <div className='card'>
                                        <div className="card-body">
                                            <DataTable
                                                className='dartTable'
                                                data-tag="allowRowEvents"
                                                columns={columns}
                                                data={listData}
                                                striped={true}
                                                noDataComponent={"Không tìm thấy dữ liệu"}

                                                pagination
                                                paginationComponentOptions={paginationComponentOptions}
                                                paginationServer={true}
                                                paginationTotalRows={this.state.totalCount}
                                                paginationRowsPerPageOptions={[10,20,50,100]}
                                                onChangePage={(page) => {
                                                    this.onPageChange(0,page)
                                                }}
                                                onChangeRowsPerPage={(pageSize, pageNum) => {
                                                    this.onPageChange(pageSize, pageNum)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientManage);
